
export default {
  props: {
    slice: { type: Object, default: Function },
  },
  computed: {
    refLabel() {
      return 'team-member'
    },
    members() {
      return this.slice.items.map((item) => item.team_member)
    },
  },
}
